@import 'bootstrap_customization_vars';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .btn#{$infix}-small {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
    }
  }
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .btn#{$infix}-large {
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
    }
  }
}

@each $color-name, $color in $hiji-colors {
  .hiji-bg-#{$color-name} {
    background-color: var(--hiji\-#{$color-name}) !important;
    color: var(--hiji\-#{$color-name}-contrast) !important;
  }
  .hiji-text-contrast-#{$color-name} {
    color: var(--hiji\-#{$color-name}-contrast) !important;
  }
  .hiji-text-#{$color-name} {
    color: var(--hiji\-#{$color-name}) !important;
  }
}

$utilities: map-merge(
  $utilities,
  (
    'font-size': (
      property: font-size,
      class: my-fs,
      responsive: true,
      values: (
        1: 0.7rem,
        2: 0.9rem,
        3: 1rem,
        4: 1.2rem,
        5: 2rem,
        initial: initial,
      ),
    ),
  )
);

@each $prop in (width, height) {
  $utilities: map-merge(
    $utilities,
    (
      $prop:
        map-merge(
          map-get($utilities, $prop),
          (
            responsive: true,
            values:
              map-merge(
                map-get(map-get($utilities, $prop), 'values'),
                (
                  200px: 200px,
                  initial: initial,
                )
              ),
          )
        ),
    )
  );
}

$utilities: map-merge(
  $utilities,
  (
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          state: hover,
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          state: hover,
          responsive: true,
        )
      ),
  )
);
