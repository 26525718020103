@use 'sass:color';

@import 'animate.css/animate.min.css';

@import 'bootstrap_init';

@import 'forms';
@import 'spin';
@import 'shadows';
@import 'headers';
@import 'bootstrap_customization';
@import 'section_counter';
@import 'tables';
@import 'ic2-switch';
@import 'dark_mode';
@import 'montserrat';

* {
  scrollbar-width: thin;
  scrollbar-color: light;
}

:root {
  --wave-strong: #{$primary};
  --wave-light: #{color.adjust($primary, $lightness: 20%)};
  html {
    color-scheme: light; //overriden by dark-mode class
  }
}

::placeholder {
  opacity: 0.5;
}

html {
  font-size: 13px !important;
}

body {
  font-family: Montserrat, 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: white; //pour l'entete sur safari iOS
  min-height: 100%;
  @each $color-name, $color in $hiji-colors {
    --hiji-#{$color-name}: #{$color};
    --hiji-#{$color-name}-light: #{color.adjust($color, $lightness: 20%)};
    --hiji-#{$color-name}-dark: #{color.adjust($color, $lightness: -20%)};
    --hiji-#{$color-name}-contrast: #{color-contrast($color)};
    //@debug if(function-exists(color-contrast), 'color-contrast existe', 'existe pas');
    //@debug color-contrast($color);
  }
}

hiji-root {
  background-color: white;
  display: block;
}

label {
  font-weight: 500;
  margin-bottom: unset;
  line-height: 1;

  &[for] {
    user-select: none;
  }
  &:focus {
    color: $primary;
  }
}

a {
  //color: $primary; viré car on override $link-color
  text-decoration: none;
  &[href] {
    cursor: pointer;
  }
}

a:hover {
  //color: $primary; viré car on override $link-color
  //text-decoration: none;
}

.no-hover:hover {
  text-decoration: none;
}

.big-tooltip {
  --bs-tooltip-max-width: 500px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.underline {
  text-decoration: underline;
}

.hover-underline:hover {
  text-decoration: underline;
}

.no-select {
  user-select: none;
}

.input-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
}

.input-with-icon {
  padding-right: 2.5rem !important;
}

@media print {
  .hide-on-print {
    display: none;
  }
}
.pageBreak {
  page-break-after: always;
}

.align-icon {
  min-width: 110px;
  text-align: center;
}

/**
Added for angular-editor with emails
*/
.mt-small {
  margin-top: 1.5rem;
}

.mt-big {
  margin-top: 2.25rem;
}
