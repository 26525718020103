/* useless car déjà computed au moment ou ça passe en dark ?

 :root{
  @media (prefers-color-scheme: dark) {
    --bs-white-rgb: #283144;
  }
}
  */

$bottom-most: #121212;
$bottom-layer: #1c1c1e;
$top-layer: #2c2c2e;
$topmost-layer: #3a3a3c;

.dark-mode {
  color-scheme: dark;
  color: white;
  --bs-body-color-rgb: 255, 255, 255;

  * {
    scrollbar-width: thin;
    scrollbar-color: dark;
  }

  body {
    color-scheme: dark;
    color: white;
    background-color: $bottom-layer !important;
  }
  hiji-root {
    background-color: $bottom-layer !important;
  }

  .bg-white {
    background-color: #283144 !important;
    color: white;
  }
  .card {
    background-color: #283144; // !important; pour pouvoir faire des bg-x sur les card
    color: white;
  }

  .table {
    color: white;
  }
  .textassistList {
    //Voir pour mettre un bg-white dans le composant textassist
    background: #283144 !important;
    color: white;
  }

  .bg-light-30p {
    background-color: $bottom-layer !important;
  }

  hiji-front-layout {
    header {
      background-color: $top-layer !important;
    }

    aside {
      border-right: unset !important;
      background-color: $top-layer !important;
    }
    nav {
      .hiji-menu-item,
      .hiji-menu-mini-item {
        color: white !important;
      }
    }
  }
  hiji-back-layout {
    header {
      background-color: $top-layer !important;
      color: white !important;
      border-bottom: unset !important;
    }

    aside {
      border-right: unset !important;
      background-color: $top-layer !important;
    }
    nav {
      .item,
      .sub-item {
        color: white !important;
      }
      .sub-item {
        &:hover {
          background-color: color.scale($top-layer, $lightness: 10%) !important;
        }
        &.active {
          background-color: color.scale($top-layer, $lightness: 5%) !important;
        }
      }
    }
  }
  //backoffice
  /* removed because we went for bg-light-30p
  .wrapper > div {
    background-color: #283144 !important;
  }
  
  .wavy {
    background-color: #1a3c82 !important;
  }*/

  section {
    background-color: #283144 !important;
    color: white;
  }

  .table > :not(caption) > * > * {
    background-color: inherit;
    color: white;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: rgb(48 57 76);
    color: white;
  }

  .modal-content {
    background-color: #283144 !important;
    h4,
    p,
    .btn-outline-medium {
      color: white;
    }
  }

  option:not(:first-child) {
    color: #314559 !important;
  }
}
